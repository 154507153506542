import 'reflect-metadata'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "./filters";
import "./registerServiceWorker";

// @ts-ignore
import VueLoading from "vuex-loading";
// @ts-ignore
import Vuebar from "vuebar";
// @ts-ignore
import VueMoment from "vue-moment";
import VueObserveVisibility from "vue-observe-visibility";
import "current-device";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faWindowClose,
  faBriefcase,
  faDownload,
  faFileInvoice,
  faFileSignature,
  faPiggyBank,
  faUpload,
  faInfoCircle,
  faBook
} from "@fortawesome/free-solid-svg-icons";
import {
  faCalendarAlt,
  faCreditCard,
  faEnvelope
} from "@fortawesome/free-regular-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from "@fortawesome/vue-fontawesome";
import Toasted from "vue-toasted";
import permissions from "@/modules/permissions/plugin";

// @ts-ignore
import svgxhr from "webpack-svgstore/dist/helpers/svgxhr";

svgxhr("images/icons-sprite.svg");

library.add(
  faWindowClose,
  faUpload,
  faDownload,
  faFileSignature,
  faEnvelope,
  faCreditCard,
  faCalendarAlt,
  faBriefcase,
  faFileInvoice,
  faPiggyBank,
  faInfoCircle,
  faBook
);

Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueLoading);
Vue.use(Vuebar);
Vue.use(VueMoment);
Vue.use(VueObserveVisibility);
Vue.use(Toasted, {
  position: "bottom-right",
  duration: "2000"
});

Vue.use(permissions);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  // @ts-ignore
  render: h => h(App, { key: store?.state?.auth?.user?.id ?? 0 }),
  // @ts-ignore
  vueLoading: new VueLoading({ useVuex: true, moduleName: "loading" })
}).$mount("#app");
