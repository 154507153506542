import api from "@/api";
import { createActionHelpers } from "vuex-loading";
import orderBy from "lodash/orderBy";
import { CONTRACT_STATUS_RANK } from "@/store/modules/loans";

const { startLoading } = createActionHelpers({ moduleName: "loading" });

export default {
  namespaced: true,

  state: {
    depositContract: {},
    depositContracts: [],
    depositContractPages: 0,
    depositDocuments: [],
    depositPayments: []
  },

  actions: {
    async createDepositContract(
      { commit, dispatch },
      { contractData, files, documentCategoryId }
    ) {
      const { data: deposit } = await startLoading(
        dispatch,
        "deposits:contract:save",
        () =>
          api.postDepositContract({ contractData, files, documentCategoryId })
      );

      commit("SET_DEPOSIT_CONTRACT", { deposit });
    },
    async loadDepositContracts({ commit, dispatch, rootGetters }) {
      const {
        data: { content: contracts, totalPages }
      } = await startLoading(dispatch, "deposits:contracts:fetch", () =>
        api.getDepositContracts()
      );

      const deposits = orderBy(
        contracts,
        [
          value =>
            CONTRACT_STATUS_RANK[value.status.code] ||
            rootGetters["classifiers/classifierByName"](
              value.status.classifier,
              value.status.name
            ).id
        ],
        ["asc"]
      );

      commit("SET_DEPOSIT_CONTRACTS", { deposits, totalPages });
    },
    /** Single deposit contract */
    async loadDepositContract({ commit, dispatch }, { contractId }) {
      const { data: deposit } = await startLoading(
        dispatch,
        `deposits:contract:${contractId}:fetch`,
        () => api.getDepositContract({ id: contractId })
      );

      commit("SET_DEPOSIT_CONTRACT", { deposit });
    },
    resetDepositContract({ commit }) {
      commit("RESET_DEPOSIT_CONTRACT");
    },
    /** --- */
    async loadDepositResources({ commit, dispatch }, { contractId }) {
      const {
        data: { content: documents }
      } = await startLoading(dispatch, "resources:fetch", () =>
        api.getDepositContractDocuments({ id: contractId })
      );

      commit("SET_DEPOSIT_CONTRACT_DOCUMENTS", { documents });
    },
    async saveDepositResource(
      { dispatch, commit },
      { contractId, files, comment, documentCategoryId }
    ) {
      const { data: resource } = await startLoading(
        dispatch,
        `resource:save`,
        () =>
          api.postDepositContractDocument({
            id: contractId,
            files,
            comment,
            documentCategoryId
          })
      );
      commit("SET_DEPOSIT_CONTRACT_RESOURCE", { resource });
    },
    async loadDepositPayments({ dispatch, commit }, { contractId }) {
      commit("SET_DEPOSIT_PAYMENTS", { payments: [] });
      const { data: payments } = await startLoading(
        dispatch,
        "deposits:payments:fetch",
        () => api.getDepositContractPayments({ id: contractId })
      );
      commit("SET_DEPOSIT_PAYMENTS", { payments });
    },
    async signDepositContract({ dispatch, commit }, { contractId }) {
      const { data: deposit } = await startLoading(
        dispatch,
        "deposits:contract:sign:save",
        () => api.postDepositContractSign({ id: contractId })
      );

      commit("SET_DEPOSIT_CONTRACT", { deposit });
    },
    async loadDepositContractTemplatePreview(
      { dispatch },
      { contractId, templateId, languageId }
    ) {
      await startLoading(dispatch, `resource:${templateId}:download`, () =>
        api.getDepositContractPreviewTemplate({
          contractId,
          templateId,
          languageId
        })
      );
    }
  },

  mutations: {
    SET_DEPOSIT_CONTRACT(state, { deposit }) {
      state.depositContract = deposit;
    },
    RESET_DEPOSIT_CONTRACT(state) {
      state.depositContract = {};
    },

    SET_DEPOSIT_CONTRACTS(state, { deposits, totalPages }) {
      state.depositContracts = deposits;
      state.depositContractPages = totalPages;
    },
    SET_DEPOSIT_CONTRACT_DOCUMENTS(state, { documents }) {
      state.depositDocuments = documents;
    },
    SET_DEPOSIT_CONTRACT_RESOURCE(state, { resource }) {
      state.depositDocuments.push(resource);
    },
    SET_DEPOSIT_PAYMENTS(state, { payments }) {
      state.depositPayments = payments;
    }
  }
};
