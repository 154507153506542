import api from "@/api";
import { createActionHelpers } from "vuex-loading";
const { startLoading } = createActionHelpers({ moduleName: "loading" });

export default {
  namespaced: true,

  state: {
    allProducts: [],
    products: [],
    productsNew: [],
    loanProducts: [],
    depositProducts: [],
    feeConfigs: null
  },

  getters: {
    productById: state => id =>
      state.products.find(product => product.id === id),
    productsByType: () => ({ code, id }, products) => {
      if (products) {
        return products.filter(
          ({ active, availableInPortal, productTypeId, productType }) =>
            (productTypeId === id || productType === code) && (availableInPortal && active)
        );
      }
      return []
    },
    allProductsByType: ({ allProducts }) => ({ code, id }, activeCustomer) => {
      return allProducts.filter(
        ({ active, availableInPortal, productTypeId, productType, sectorId }) =>
          (productTypeId === id || productType === code) && (availableInPortal && active) && (sectorId === activeCustomer.sectorId)
      )
    }
  },

  actions: {
    async loadProducts({ commit, dispatch }, params) {
      // commit("SET_PRODUCTS", { products: [] });
      commit("SET_PRODUCTS_NEW", { products: [] });
      const response = await startLoading(dispatch, "products:fetch", () => {
        return api.getProducts(params);
      });

      // commit("SET_PRODUCTS", { products: response.data });
      commit("SET_PRODUCTS_NEW", { products: response.data });
    },
    async loadDepositProducts({ commit, dispatch }, params) {
      commit("SET_DEPOSIT_PRODUCTS", { depositProducts: [] });
      const response = await startLoading(dispatch, "products:fetch", () => {
        return api.getProducts(params);
      });

      commit("SET_DEPOSIT_PRODUCTS", { depositProducts: response.data });
    },
    /** */
    async loadLoanProducts({ commit, dispatch }, params) {
      // commit("SET_PRODUCTS", { products: [] });
      commit("SET_LOAN_PRODUCTS", { loanProducts: [] });

      const response = await startLoading(dispatch, "products:fetch", () => {
        return api.getProducts(params);
      });
      const products = response.data.filter((current) => current.group !== "DEPOSIT")

      // commit("SET_PRODUCTS", { products });
      commit("SET_LOAN_PRODUCTS", { loanProducts: products });
    },
    /** */
    async loadAllProducts({ commit, dispatch, rootGetters }) {
      commit("SET_PRODUCTS", { products: [] });
      const { data: products } = await startLoading(
        dispatch,
        "products:fetch",
        () => api.getProducts()
      );

      let depositProducts = [];
      let factoringProducts = []
      if (rootGetters["settings/depositMode"]) {
        const response = await startLoading(dispatch, "products:fetch", () =>
          api.getDepositProducts()
        );
        depositProducts = response.data;
      }

      if (rootGetters["settings/factoringMode"]) {
        const response = await startLoading(dispatch, "products:fetch", () =>
          api.getFactoringProducts()
        );
        factoringProducts = response.data;
      }
      commit("SET_PRODUCTS", { products: [...products, ...depositProducts, ...factoringProducts] });
      commit("SET_ALL_PRODUCTS", { products: [...products, ...depositProducts, ...factoringProducts] });
    },
    async loadProductsDeposit({ commit, dispatch }) {
      commit("SET_PRODUCTS", { products: [] });
      const { data: products } = await startLoading(
        dispatch,
        "products:fetch",
        () => api.getDepositProducts()
      );
      commit("SET_PRODUCTS", { products });
    },
    async loadProductsFactoring({ commit, dispatch }) {
      commit("SET_PRODUCTS", { products: [] });
      const { data: products } = await startLoading(
        dispatch,
        "products:fetch",
        () => api.getFactoringProducts()
      );
      commit("SET_PRODUCTS", { products });
    },
    async loadFeeConfig ({ commit, dispatch }, { group }) {
      const { data: feeConfigs } = await startLoading(dispatch, 'product:feeConfig:fetch', () => api.getFeeConfigByGroup({ group }))

      commit('SET_FEE_CONFIGS', { feeConfigs })
    }
  },

  mutations: {
    SET_ALL_PRODUCTS(state, { products }) {
      state.allProducts = products;
    },
    SET_PRODUCTS(state, { products }) {
      state.products = products;
    },
    SET_PRODUCTS_NEW(state, { products }) {
      state.productsNew = products;
    },
    SET_LOAN_PRODUCTS(state, { loanProducts }) {
      state.loanProducts = loanProducts
    },
    SET_DEPOSIT_PRODUCTS(state, { depositProducts }) {
      state.depositProducts = depositProducts
    },
    SET_FEE_CONFIGS (state, { feeConfigs }) {
      state.feeConfigs = feeConfigs
    },
  }
};
